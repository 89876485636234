<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
					class="font_Hsemibold"
				>
					{{ $t("planGraphic") }}
				</AppText>
			</div>
			<div class="section__top-right d-flex align-center">
				<div class="d-flex align-center">
					<!-- <form-label
						v-model="query.take"
						:list="categoryList"
						component-type="dropdown"
						class="mr-15 mt-0 drop_bag"
						style="min-width: 70px"
						@input="load()"
					/> -->
				</div>
				<app-button-a class="" theme="gray-light" padding-x="24" font-weight="600" @click="openFilter">
					<img src="../../assets/icons/sliders.svg" class="mr-10" alt="" />
					<span>{{ $t("filter") }}</span>
				</app-button-a>
			</div>
		</div>
		<div class="section__top">
			<app-content-card
				v-if="isOpenFilterPanel"
				class="auction-filter mb-20 bordered"
				:class="isMobile ? 'flex-wrap' : ''"
			>
				<block-wrap
					class="auction-filter__items-wrap"
					offset-x="15"
					:count="isMobileSmall ? '1' : isMobileMedium ? 2 : '3'"
				>
					<form-label
						v-model="query.companyName"
						class="auction-filter__item"
						component-type="input"
						:title="this.$t('organizationName')"
					/>
					<form-label
						v-model="query.productName"
						class="auction-filter__item"
						component-type="input"
						:title="this.$t('productName')"
					/>
					<form-label v-model="query.tin" :title="this.$t('inn')" />
					<form-label
						v-model="query.regionId"
						class="auction-filter__item"
						:list="regions"
						item-value="id"
						item-name="name"
						:translatable="true"
						component-type="dropdown"
						:title="this.$t('region')"
						:placeholder="this.$t('chooseRegion')"
					/>
					<app-multiple-select
						ref="months"
						class="auction-filter__item"
						:multiple="true"
						:title="this.$t('month')"
						:list="months"
						:translatable="true"
						select-type="checkbox"
						:single-item="true"
						@change="pickMonths($event)"
					></app-multiple-select>
					<form-label
						v-model="query.year"
						class="auction-filter__item"
						:list="years"
						item-name="name"
						component-type="dropdown"
						:title="this.$t('year')"
						:placeholder="this.$t('chooseYear')"
					/>
				</block-wrap>
				<div class="auction-filter__buttons-wrap mt-20">
					<div class="auction-filter__buttons">
						<app-button-a
							:width="0"
							padding-x="10"
							@click="clearFilter"
							class="color-text-light"
							theme="transparent"
						>
							<img src="@/assets/icons/trash.svg" class="mr-10" alt="" style="width: 18px" />
							{{ $t("clear") }}
						</app-button-a>
						<app-button-a @click="closeFilter" width="108" theme="gray-light">
							{{ $t("close") }}
						</app-button-a>
						<app-button-a width="108" @click="fetchData()">{{ $t("show") }}</app-button-a>
					</div>
				</div>
			</app-content-card>
		</div>
		<div class="section__body plan-graph" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<div class="table-block" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th>№</th>
							<th style="max-width: 300px">{{ $t("organizationName") }}</th>
							<th>{{ $t("inn") }}</th>
							<th style="max-width: 250px">{{ $t("region") }}</th>
							<th style="max-width: 250px">{{ $t("district") }}</th>
							<th style="max-width: 250px">{{ $t("address") }}</th>
							<th>{{ $t("typeOrganization") }}</th>
							<th style="max-width: 300px">{{ $t("productName") }}</th>
							<th>{{ $t("amountShort") }}</th>
							<th>{{ $t("month") }}</th>
							<th>{{ $t("year") }}</th>
							<!-- <th style="text-align: center">{{ $t("action") }}</th> -->
						</tr>
					</thead>
					<tbody>
						<template v-if="data?.length">
							<tr v-for="(item, index) in data" :key="item.id">
								<td class="text-center">{{ index + 1 }}</td>
								<td style="max-width: 300px">
									{{ item.companyName | translate }}
								</td>
								<td>
									{{ item.tin }}
								</td>
								<td style="max-width: 250px" class="text-center">
									{{ item.regionName | translate }}
								</td>
								<td style="max-width: 250px" class="text-center">
									{{ item.districtName | translate }}
								</td>
								<td style="max-width: 250px" class="text-center">
									{{ item.address }}
								</td>
								<td class="text-center">{{ item.sourceFinanceName | translate }}</td>
								<td style="max-width: 300px">
									{{ item.productName | translate }}
								</td>
								<td class="text-center">{{ item.productQuantity }} {{ item.measureName }}</td>
								<td class="text-center">{{ getMonthName(item.month) | translate }}</td>
								<!-- <td class="text-center">{{ monthToQuarter(item.month) }} квартал</td> -->
								<td class="text-center">{{ item.year }}</td>
								<!-- <td class="text-center">
									<AppButton
										theme="main"
										sides="20"
										height="30"
										weight="600"
										:radius="6"
										font-size="14"
										@click="openModal(item)"
										class="font_Hsemibold"
										>{{ $t("view") }}
									</AppButton>
								</td> -->
							</tr>
						</template>
						<template v-else>
							<tr>
								<td colspan="8" style="text-align: center">{{ $t("noData") }}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>

			<AppModal
				@ok="close()"
				v-if="modalVisible"
				v-model="modalVisible"
				:closeButton="false"
				ref="planScheduleModal"
				max-width="688"
			>
				<div class="modal__holder">
					<div class="label-input-group mb-30">
						<app-text size="18" weight="600" class="font_Hsemibold"
							>{{ $t("plan_schedule") }} № {{ planSchedule.planNumber }}</app-text
						>
					</div>
					<div class="plan-grid-container">
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5"> {{ $t("productName") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.productName | translate }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("amountShort") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.productQuantity }} {{ planSchedule.measureName }}</span
							>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5"> {{ $t("month") }}: </label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.month | monthString }} {{ planSchedule.year }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("sourceOfFinancing") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.sourceFinanceName | translate }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5"> {{ $t("region") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.regionName | translate }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5"> {{ $t("district") }}: </label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.districtName | translate }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("address") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.address }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5"> {{ $t("deliveryTime") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.createdDate | moment("DD.MM.YYYY") }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5"> {{ $t("deliveryConditions") }}:</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.deliveryConditionName | translate }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("customerInn") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.deliveryConditionName | translate }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5"> {{ $t("customerName") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium">
								{{ planSchedule.companyName | translate }}
							</span>
						</div>
						<div class="d-flex flex-column">
							<label class="fs-12 color-text mb-5">{{ $t("customerPhone") }} :</label>
							<span class="fs-14 color-text fw-500 font_Hmedium"> {{ planSchedule.phones }} </span>
						</div>
					</div>
					<div class="footer_modal">
						<AppButton
							theme="gray-light"
							sides="20"
							height="40"
							weight="600"
							font-size="14"
							class="font_Hsemibold"
							@click="closeModal"
							>{{ $t("close") }}
						</AppButton>
					</div>
				</div>
			</AppModal>

			<pagination :total="total" :perPage="query.take" @paginate="pagination($event)"> </pagination>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppButtonA from "../../components/shared-components/AppButtonA";
import FormLabel from "../../components/shared-components/FormLabel";
import AppButton from "../../components/shared-components/AppButton";
import AppPagination from "../../components/shared-components/AppPagination";
import Pagination from "../../components/shared-components/Pagination";
import AppModal from "../../components/shared-components/AppModal";
import ClientService from "@/services/api/client-service";
import ReferenceService from "@/services/api/reference-service";
import AppContentCard from "@/components/shared-components/AppContentCard";
import BlockWrap from "@/components/shared-components/BlockWrap";
import AppMultipleSelect from "@/components/shared-components/AppMultipleSelect";
import months from "@/static/months";
import years from "@/static/years";

export default {
	name: "index",
	components: {
		AppModal,
		AppPagination,
		AppButton,
		FormLabel,
		AppButtonA,
		AppText,
		Pagination,
		AppContentCard,
		BlockWrap,
		AppMultipleSelect,
	},
	data() {
		return {
			query: {
				regionId: null,
				companyName: null,
				productName: null,
				tin: null,
				list: [],
				year: null,
				skip: 0,
				take: 10,
			},
			monthList: [],
			queryClear: {
				regionId: null,
				companyName: null,
				productName: null,
				tin: null,
				list: [],
				year: null,
				skip: 0,
				take: 10,
			},
			total: 0,
			isOpenFilterPanel: false,
			categories: [],
			regions: [],
			months: months,
			years: [],
			quarters: [],
			data: null,
			planSchedule: {},
			categoryList: [
				{
					name: "10",
					value: 10,
				},
				{
					name: "20",
					value: 20,
				},
				{
					name: "30",
					value: 30,
				},
			],
			modalVisible: false,
		};
	},
	async created() {
		await this.fetchData();
		this.setYears();
		this.queryClear = JSON.parse(JSON.stringify(this.query));
	},
	methods: {
		async fetchData() {
			try {
				const res = await ClientService.getPlanScheduleList(this.normalizeQuery());
				this.data = res.data.result.data;
				this.total = res.data.result.total;
			} catch (error) {
				this.$notification.error(error);
			} finally {
				this.loading = false;
			}
		},
		getRegions() {
			if (this.regions.length > 0) return;

			ReferenceService.getRegions().then(
				(response) => {
					this.regions = response.data.result.data;
				},
				(error) => {
					this.$notify({ type: "error", text: error });
				}
			);
		},
		setYears() {
			const currentYear = +this.$moment(new Date()).format("YYYY");
			this.years = years.filter((year) => year.value >= currentYear);
		},
		pickMonths(e) {
			if (!this.query.hasOwnProperty("list") || e.length === 0) {
				this.query["list"] = [];
			}
			if (this.query.list.includes(e.id)) {
				this.query.list = this.query.list.filter((item) => item !== e.id);
			} else {
				e.map((e) => this.query.list.push(e.id));
				// this.query.list.push(e.id);
			}
		},
		clearFilter() {
			this.query = JSON.parse(JSON.stringify(this.queryClear));
			this.$refs.months.clearAllSelected();
		},
		pagination(skip) {
			this.query.skip = skip - this.query.take;
			this.fetchData();
		},
		openFilter() {
			this.getRegions();
			this.isOpenFilterPanel = !this.isOpenFilterPanel;
		},
		openModal(item) {
			this.planSchedule = item;
			this.modalVisible = !this.modalVisible;
		},
		closeModal() {
			this.modalVisible = !this.modalVisible;
			this.$refs.scheduleModal.close();
		},
		normalizeQuery() {
			const list = this.query.list;
			let queryList = "",
				query = JSON.parse(JSON.stringify(this.query));

			if (list.length > 0) {
				list.forEach((item) => {
					queryList += "&list=" + item;
				});
			}
			delete query.list;

			for (const item in query) {
				if (query[item] === null) {
					delete query[item];
				}
			}

			query = new URLSearchParams(query);
			query += queryList;

			return query;
		},
		monthToQuarter(month) {
			if (month >= 1 && month <= 3) {
				return 1;
			} else if (month > 3 && month <= 6) {
				return 2;
			} else if (month > 6 && month <= 9) {
				return 3;
			} else {
				return 4;
			}
		},
		getMonthName(month) {
			return this.months.find((item) => item.id === month).name;
		},
		async closeFilter() {
			this.isOpenFilterPanel = false;
			await this.fetchData();
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/mixins";
app-content-card {
	width: 100%;
}
.auction-filter {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__item {
		width: #{size(225px, 995px)};
	}

	&__item-large {
		width: #{size(295px, 995px)};
	}

	// &__items-wrap {
	// 	width: calc(100% - 200px);
	// }

	&__reset-button {
		margin-left: auto;
		margin-bottom: 40px;
		margin-right: -10px;
	}

	&__buttons-wrap {
		display: flex;
		justify-content: flex-end;
		gap: 15px;
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		gap: 15px;
		// width: 231px;
		// margin-left: -31px;
	}
}

.auction-header {
	display: flex;
	align-items: center;

	&__input {
		flex-grow: 1;
		margin-right: 15px;
	}

	&__button {
		svg {
			margin-left: -8px;
		}
	}
}

.drop_bag {
	.app-dropdown {
		&__header {
			background: #dfeffd !important;
		}
	}
}
.footer_modal {
	display: flex;
	justify-content: flex-end;
	padding-top: 40px;
}
@media (max-width: 800px) {
	.auction-filter__items-wrap {
		width: 100%;
	}

	.auction-filter__buttons-wrap {
		width: 100%;
	}

	.auction-filter__buttons {
		margin-left: 0;
		width: auto;
	}

	.auction-filter__reset-button {
		margin-bottom: 20px;
	}

	.auction-filter__reset-button {
		margin-left: 0;
	}
}
</style>
