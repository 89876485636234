<template>
	<div class="modal" :class="{ visible: !hidden }">
		<div class="modal__content" :style="`max-width: ${maxWidth}px`">
			<button v-if="closeButton" class="modal__close" @click="close">
				<img src="../../assets/icons/close.svg" alt="" />
			</button>
			<div style="overflow: hidden; width: 100%; border-radius: 8px">
				<slot />
			</div>
		</div>
		<div class="overlay" @click="close"></div>
	</div>
</template>

<script>
import "../../assets/styles/components/app-modal.scss";

export default {
	name: "AppModal",
	components: {},
	props: {
		maxWidth: {
			type: String,
			default: "",
		},
		closeButton: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			hidden: true,
		};
	},
	methods: {
		close() {
			this.hidden = true;
			setTimeout(() => {
				this.$emit("input", false);
			}, 200);
		},
	},
	async mounted() {
		setTimeout(() => {
			this.hidden = false;
		}, 10);
	},
};
</script>

<style scoped></style>
