const months = [
    { name: {ru: 'Январь', uz: 'Yanvar', cyrl: 'Январь'}, id: 1 },
    { name: {ru: 'Февраль', uz: 'Fevral', cyrl: 'Февраль'}, id: 2 },
    { name: {ru: 'Март', uz: 'Mart', cyrl: 'Март'}, id: 3 },
    { name: {ru: 'Апрель', uz: 'Aprel', cyrl: 'Апрель'}, id: 4 },
    { name: {ru: 'Май', uz: 'May', cyrl: 'Май'}, id: 5 },
    { name: {ru: 'Июнь', uz: 'Iyun', cyrl: 'Июнь'}, id: 6 },
    { name: {ru: 'Июль', uz: 'Iyul', cyrl: 'Июль'}, id: 7 },
    { name: {ru: 'Август', uz: 'Avgust', cyrl: 'Август'}, id: 8 },
    { name: {ru: 'Сентябрь', uz: 'Sentyabr', cyrl: 'Сентябрь'}, id: 9 },
    { name: {ru: 'Октябрь', uz: 'Oktyabr', cyrl: 'Октябрь'}, id: 10 },
    { name: {ru: 'Ноябрь', uz: 'Noyabr', cyrl: 'Ноябрь'}, id: 11 },
    { name: {ru: 'Декабрь', uz: 'Dekabr', cyrl: 'Декабрь'}, id: 12  }
];

export default months;