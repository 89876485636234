const years = [
    { name: 2020, value: 2020 },
    { name: 2021, value: 2021 },
    { name: 2022, value: 2022 },
    { name: 2023, value: 2023 },
    { name: 2024, value: 2024 },
    { name: 2025, value: 2025 },
    { name: 2026, value: 2026 },
    { name: 2027, value: 2027 },
    { name: 2028, value: 2028 },
    { name: 2029, value: 2029 },
    { name: 2030, value: 2030 },
    { name: 2031, value: 2031 },
    { name: 2032, value: 2032 },
    { name: 2033, value: 2033 },
    { name: 2034, value: 2034 },
    { name: 2035, value: 2035 },
    { name: 2036, value: 2036 },
    { name: 2037, value: 2037 },
    { name: 2038, value: 2038 },
    { name: 2039, value: 2039 },
    { name: 2040, value: 2040 }
];

export default years;