import Vue from "vue";

export default {
	url: "/api-admin/Reference/",
	api: Vue.prototype.$api,

	getRegions() {
		return this.api.get(`${this.url}GetAllRegion?skip=${0}&take=${50}`);
	},

	getDistricts() {
		return this.api.get(`${this.url}GetAllDistrict?skip=${0}&take=${500}`);
	},

	getCountries() {
		return this.api.get(`${this.url}GetAllCountry?skip=0&take=500`);
	},

	async getProductCategories() {
		return await this.api.get(`${this.url}GetAllProductCategory?skip=0&take=500`);
	},

	getMeasures() {
		return this.api.get(`${this.url}GetAllMeasure?skip=0&take=50`);
	},
};
