import Vue from 'vue';

export default {
    url: '/api-client/Client/',
	statisticsUrl: '/api-client/Statistic/',
    api: Vue.prototype.$api,

    getPlanScheduleList(params) {
      return this.api.get(`${this.url}GetAllPlanSchedule?${params}`);
    },

    allDirectPurchases(params) {
      return this.api.get(`${this.url}GetAllDirectPurchase`, {params})
    },
    getDirectPurchaseById(id) {
      return this.api.get(`${this.url}GetByDirectPurchase?id=${id}`)
    },

	// ----------- Statistics -----------------------
	purchasingInfoByShop(params) {
		return this.api.get(`${this.statisticsUrl}GetAllShopRegion`, {params});
	},

	purchasingInfoByAuction(params) {
		return this.api.get(`${this.statisticsUrl}GetAllAuctionRegion`, {params});
	},

	purchasingInfoBySelection(params) {
		return this.api.get(`${this.statisticsUrl}GetAllSelectionRegion`, {params});
	},

	purchasingInfoByTender(params) {
		return this.api.get(`${this.statisticsUrl}GetAllTenderRegion`, {params});
	},

	dealSumData(params) {
		return this.api.get(`${this.statisticsUrl}GetAllDiagramRegion`, {params});
	},

	dealsAmountData(params) {
		return this.api.get(`${this.statisticsUrl}GetAllQuantityRegion`, {params});
	},

	getShopTop5(params) {
		return this.api.get(`${this.statisticsUrl}GetAllShopTop5`, {params});
	},

	getAuctionTop5(params) {
		return this.api.get(`${this.statisticsUrl}GetAllAuctionTop5`, {params});
	},

	getSelectionTop5(params) {
		return this.api.get(`${this.statisticsUrl}GetAllSelectionTop5`, {params});
	},

	getTenderTop5(params) {
		return this.api.get(`${this.statisticsUrl}GetAllTenderTop5`, {params});
	},

	getNumericStatistics() {
		return this.api.get(`${this.statisticsUrl}NumericStatistics`);
	}
}