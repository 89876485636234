<template>
  <component class="a-btn-a" :class="className" :style="style" :is="tag" v-bind="$attrs" v-on="listeners" :disabled="disabled">
    <slot/>
  </component>
</template>
<script>
  import '../../assets/styles/components/app-button-a.scss';
  export default {
    name: 'AppButtonA',
    props: {
      tag: {
        type: String,
        default: 'button'
      },
      paddingX: {
        type: [Number, String],
        default: 15
      },
      height: {
        type: [Number, String],
        default: 40
      },
      width: {
        type: [Number, String]
      },
      radius: {
        type: [Number, String],
        default: 8
      },
      fontSize: {
        type: [Number, String],
        default: 14
      },
      lineHeight: {
        type: [Number, String],
        default: 18
      },
      fontWeight: {
        type: [Number, String],
        default: 600
      },
      theme: {
        type: String,
        default: 'accent'
      },
      text: Boolean,
      disabled: Boolean,
      outline: Boolean,
      noFocus: Boolean,
      flex: Boolean
    },
    inheritAttrs: false,
    computed: {
      className() {
        const name = 'a-btn-a--';
        const className = [
          name + 'th-' + this.theme,
        ];
        if(this.flex) {
          className.push(name + 'fl');
        }
        if(this.width) {
          className.push(name + 'wd');
        }
        if(this.outline) {
          className.push(name + 'line');
        }
        if(this.noFocus) {
          className.push(name + 'nf');
        }
        if(this.text) {
          className.push(name + 'text');
        }
        return className;
      },
      style() {
        const styles = {
          '--padding-x': this.paddingX + 'px',
          '--radius': this.radius + 'px',
          '--font-size': this.fontSize + 'px',
          '--font-weight': this.fontWeight,
          '--height': this.height + 'px',
          '--line-height': this.lineHeight + 'px',
        };
        if(this.width) {
          styles['--wd'] = this.width + 'px';
        }
        return styles;
      },
      listeners() {
        if(this.disabled) return {};

        return {
          click: (event) => this.$emit('click', event)
        }
      }
    }
  }
</script>
