<template>
    <component class="app-content-card" :is="tag" :style="style" :class="className">
        <slot/>
    </component>
</template>
<script>
    import '../../assets/styles/components/app-content-card.scss';
    export default {
      name: 'AppContentCard',
      props: {
        paddingX: {
          type: [String, Number],
          default: 20
        },
        paddingY: [String, Number],
        radius: {
          type: [String, Number, Array],
          default: 8
        },
        border: Boolean,
        tag: {
          type: String,
          default: 'div'
        },
        theme: {
          type: String,
          default: 'default'
        }
      },
      computed: {
        className() {
          const name = 'app-content-card--';
          const className = [
            name + 'theme-' + this.theme,
            {
              [name + 'border']: this.border
            }
          ];
          return className;
        },
        style() {
          let radius = this.radius + 'px';
          if(Array.isArray(this.radius)) {
            radius = this.radius.map(rad => rad + 'px').join(' ');
          }
          const style = {
            '--padding-x': this.paddingX + 'px',
            '--padding-y': (this.paddingY || this.paddingX) + 'px',
            '--radius': radius
          }
          return style;
        }
      }
    }
</script>